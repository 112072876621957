import React from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import { Container } from "../elements/Layout";
import { Route } from "../elements/Text";
import { HOME_PAGE } from "../../config/routes";
import theme from "../../config/theme";

const StyledPage = styled.div`
  padding: 100px 0;
  font-size: 16px;

  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .text {
    margin-bottom: 30px;
  }

  a {
    color: ${theme.colors.brand};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .content {
    margin: 0 auto;
    max-width: 800px;
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    .title {
      font-size: 46px;
    }
  }
`;

const NotFoundPage = ({ location }) => (
  <StyledPage>
    <SEO title="Not found" location={location} />
    <section>
      <Container>
        <div className="content">
          <h1 className="title">Page not found</h1>
          <p className="text">Oops. The page you're looking for is not here.</p>
          <Route route={HOME_PAGE}>Return home</Route>
        </div>
      </Container>
    </section>
  </StyledPage>
);

export default NotFoundPage;
